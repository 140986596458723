import RowDescription from './RowDescription'
import services from '../../data/services'

export default function Content(props) {
  return (
    <div id="content" className="container py-5">
      {services.map((item, index) => {
        return (
          <RowDescription
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            button={item.button}
            index={index}
          />
        )
      })}
    </div>
  )
}
