import { RowDescription } from '../../components/index'
import medical from '../../data/medical'
import { PageTitle } from '../../hooks/index'
import PageHeader from '../../components/PageHeader'

export default function Medical(props) {
  PageTitle('Lucent Clinic - Services - Medical Treatments')

  return (
    <div id="medical">
      <PageHeader
        title="Medical Treatments"
        image={require('../../assets/images/background/medical.jpg')}
      />
      {medical.map((item, index) => {
        return (
          <div key={index} className="medical-item">
            <RowDescription
              background={item.image}
              title={item.name}
              textColor="dark"
              textPosition="right"
              height="300px"
              className="medical-category"
            />
            {item.items.map((item, index) => {
              return (
                <div key={index} className="content my-5">
                  <div className="container">
                    <div className="row justify-content-between">
                      <div className="col-lg-6">
                        <h1>{item.name}</h1>
                        <p>{item.description}</p>
                        {item.item_descriptions && (
                          <ul>
                            {item.item_descriptions.map((item, index) => {
                              return <li key={index}>{item}</li>
                            })}
                          </ul>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <div className="row align-items-center">
                          <div className="col-1">
                            <img
                              src={require('../../assets/images/icon/durasi.png')}
                              style={{ height: '42px' }}
                              alt="durasi"
                            />
                          </div>
                          <div className="col-10 col-lg-11 ms-3 ms-lg-0">
                            <small>Durasi Treatment</small>
                            <h5>{item.durasi}</h5>
                          </div>
                        </div>
                        <div className="row mt-3 align-items-center">
                          <div className="col-1">
                            <img
                              src={require('../../assets/images/icon/fungsi.png')}
                              style={{ height: '42px' }}
                              alt="fungsi"
                            />
                          </div>
                          <div className="col-10 col-lg-11 ms-3 ms-lg-0">
                            <small>Fungsi</small>
                            {item.fungsi.map((item, index) => {
                              return <h5 key={index}>{item}</h5>
                            })}
                          </div>
                        </div>
                        <div className="row mt-3 d-flex align-items-center">
                          <div className="col-1">
                            <img
                              src={require('../../assets/images/icon/prosedur.png')}
                              style={{ height: '42px' }}
                              alt="prosedur"
                            />
                          </div>
                          <div className="col-10 col-lg-11 ms-3 ms-lg-0">
                            <small>Prosedur</small>
                            <h5>{item.prosedur}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
