export default function PageHedaer(props) {
  const style = {
    ...props.style,
    backgroundImage: `url(${props.image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  return (
    <div className="d-flex align-items-center page-header" style={style}>
      <div className="container text-lg-end">
        <div className="row justify-content-lg-end">
          <div className="col-lg-6 text-center text-lg-start">
            <h1>{props.title}</h1>
            {props.description && <p>{props.description}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}
