const aesthetic = [
  {
    name: 'Facial',
    image: require('../assets/images/aesthetic/facial.jpg'),
    items: [
      {
        treatment: 'Ultimate Crystal Glow',
        description:
          'Medical Facial dengan kombinasi 3 alat utama yang berfungsi untuk:',
        item_descriptions: [
          'Microdermabrasi (regenerasi sel kulit mati)',
          'Tripolar RF (merangsang kontraksi serat kolagen kulit melalui pemanasan lapisan dermis untuk membuat kulit lebih kencang)',
          'Ultrasound (menginfus bahan aktif dan untuk merevitalisasi dan memberi nutrisi pada kulit)',
          ' Dilengkapi dengan Serum Whitening dan Masker Whitening sehingga kulit lebih cerah',
        ],
        duration: '90 Menit',
      },
      {
        treatment: 'Brightening Pearl',
        description:
          'Perawatan wajah yang memberikan solusi untuk kulit kusam, kerutan halus/ wrinkle, expression line, menua atau aging skin',
        duration: '90 Menit',
      },
      {
        treatment: 'Retin Aging',
        description:
          'Perawatan wajah yang memberikan solusi untuk freckles dan melasma, kulit kusam, kerutan halus/ wrinkle, menua atau aging skin',
        duration: '90 Menit',
      },
      {
        treatment: 'Crystal Glow',
        description:
          'Perawatan wajah yang menggabungkan 2 fungsi alat utama yang berfungsi untuk:',
        item_descriptions: [
          'Microdermabrasi (regenerasi sel kulit mati)',
          'Ultrasound (menginfus bahan aktif dan untuk merevitalisasi dan memberi nutrisi serta meningkatkan oxygenasi pada kulit)',
          'Dilengkapi dengan masker whitening untuk mencerahkan kulit',
        ],
        duration: '90 Menit',
      },
      {
        treatment: 'Glowing C',
        description:
          'Facial dengan bahan aktif Vit C 30% adalah perawatan untuk mengatasi problem pigmentasi, kulit kusam, bercak kecoklatan dan warna kulit merata pada wajah dan menstimulasi kembali produksi collagen sehingga mengembalikan kekenyalan kulit.',
        duration: '90 Menit',
      },
      {
        treatment: 'Brightening Glow',
        description:
          'Perpaduan facial dan chemical peeling untuk mengatasi pigmentasi, bekas jerawat, dan merangsang terbentuknya collagen dan elastin baru',
        duration: '90 Menit',
      },
      {
        treatment: 'Super Reduce Acne',
        description:
          'Perawatan wajah yang berfungsi untuk mengatasi problem kulit berjerawat aktif, berkomedo dan PIH (post inflammatory hiperpigmentasi atau bekas-bekas jerawat',
        duration: '90 Menit',
      },
      {
        treatment: 'Fire and Ice Facial',
        description: [
          'Perawatan yang berfungsi untuk membuat kulit menjadi lebih glowing, cerah, kencang, dan tanpa downtime. ',
          <br key="0" />,
          <br key="1" />,
          'Setiap stepnya akan menginfus kulit dengan 3 tingkatan serum yang akan merejuvenating,healing, dan cooling effect, serta untuk perawatan bibir dan mata.Sehingga setelah selesai dapat beraktivitas kembali dengan kulit yang extra glowing.',
        ],
        duration: '90 Menit',
      },
      {
        treatment: 'Fire and Ice Facial Signature',
        description: [
          'Signature Fire and Ice Facial merupakan penggabungan Lucent Red Carpet Peel dan Fire and Ice Facial. ',
          <br key="0" />,
          <br key="1" />,
          'Red carpet peel atau yang biasa disebut silk peel merupakan MICRODERMABRASI original dari Amerika yang sudah FDA approve jadi aman sekali.',
          <br key="2" />,
          <br key="3" />,
          'Silk Peel menggunakan tiga teknologi ( EVI ) yaitu Exfoliation, Vacum, dan Infusion. Di ujung alatnya terdapat Diamond Grit yang berfungsi untuk mengangkat Sel kulit mati kemudian di infusion dengan serum yang sudah disesuaikan dengan kebutuhan kulit, diakhiri dengan vacuum diatas sel kulit mati.',
          <br key="4" />,
          <br key="5" />,
          'Dengan teknologi yang 3 in 1 ini dapat membuat hasil yang maksimal, diantaranya berfungsi untuk:',
        ],
        item_descriptions: [
          'Cerah dan Segar',
          'Lembab dan Glowing',
          'Halus, pori-pori mengecil',
          'Bersih dan jerawat berkurang',
          'Mengangkat Sel Kulit mati',
        ],
        duration: '90 Menit',
      },
    ],
  },
  {
    name: 'Ampoule',
    image: require('../assets/images/aesthetic/ampoule.jpg'),
    items: [
      {
        treatment: 'Oil Acne Serum',
        description: [
          'Dengan bahan aktif Thiopeptida (antibakteri), Tea Tree: mengurangi produksi sebaceous, antiseptik dan balsamik, dan Astringent: mengecilkan pori pori sehingga mengurangi produksi minyak berlebih serta membantu melawan tumbuhnya bakteri P.Acne dan Comedo.',
          <br key="0" />,
          <br key="1" />,
          'Gel terkonsentrasi ini bekerja pada jerawat, papula, pustula dan kemerahan. Konsentrat murninya adalah anti-inflamasi, menghilangkan penyumbatan folikel dan proliferasi bakteri.',
        ],
        duration: '3 - 5 Menit',
      },
      {
        treatment: 'Caviar Ampoule',
        description:
          'Dengan bahan aktif Ascorbyl Methylsilanol Pectinate  yang merupakan antioksidan (menangkal radikal bebas) yang membantu memperbaiki kerusakan akibat sinar matahari, memperbaiki kolagen, meningkatkan produksi kolagen dan melindungi kulit dari efek sinar UVA/UVB.',
        item_descriptions: [
          'Mengandung pelembab alami yang membantu menjaga lapisan antar sel kulit tetap utuh',
          'Membentuk moisturizer alami yang menjaga kelembaban agar kulit lebih kenyal',
        ],
        duration: '3 - 5 Menit',
      },
      {
        treatment: 'Whitening Ampoule',
        description: 'Dengan bahan aktif Kojic Acid yang berfungsi untuk ',
        item_descriptions: [
          'Mengurangi munculnya bintik-bintik penuaan pada kulit  serta menghasilkan warna kulit yang lebih cerah.',
          'Menghambat pembentukan pigmen kulit.',
          'Kulit lebih bercahaya dan lembab  serta meningkatkan elastisitas kulit ',
        ],
        duration: '3 - 5 Menit',
      },
      {
        treatment: 'Aloe Soothing Ampoule',
        description:
          'Dengan bahan aktif Ekstrak Epilobium Angustifolium merupakan anti inflamasi alami yang bekerja dengan cepat untuk menenangkan kulit yang teriritasi.',
        item_descriptions: [
          'Mengandung pelembab alami yang melakukan aksi ganda dengan memberikan nutrisi dan kelembaban pada kulit.',
          'Solusi terintegrasi untuk kulit kering dan sensitif  dan sangat cocok apabila sering digunakan.',
        ],
        duration: '3 - 5 Menit',
      },
      {
        treatment: 'Vit C Ampoule',
        description:
          'Dengan bahan aktif Ascorbyl Methylsilanol Pectinate yang merupakan antioxidant (menangkal radikal bebas) untuk melindungi kulit dari dampak  paparan sinar UV A UV B , serta meningkatkan dan menstimulasi produksi kolagen',
        duration: '3 - 5 Menit',
      },
    ],
  },
  {
    name: 'Mask',
    image: require('../assets/images/aesthetic/masks.jpg'),
    items: [
      {
        treatment: 'Dual Cell Mask',
        description:
          'Dengan bahan aktif protein alami, Rose extract dan royal jelly dengan diperkaya Alpha Arbutin yang 10 kali lebih kuat dalam efek mencerahkan kulit , mengatasi problem pigmentasi serta meremajakan sel kulit wajah',
        duration: '20 Menit',
      },
      {
        treatment: 'Sebo Mask',
        description:
          'Sebo Mask dengan bahan aktif biologis pada gelly sheet dasar yang berasal dari tumbuhan laut alami (Agar) yang terdiri dari polisakarida, mineral & elemen pelacak dan multi vitamin.',
        item_descriptions: [
          'Adenosin meningkatkan penyembuhan luka dan perbaikan jaringan. ',
          'Pterowhite dan Niacinamide sebagai sistem pertahanan dan perbaikan kulit dari kerusakan atau iritasi ',
          'Potassium Iodide secara topikal digunakan untuk lesi inflamasi',
        ],
        duration: '20 Menit',
      },
      {
        treatment: 'Regenic Mask',
        description:
          'Dengan Bahan-bahan aktif alami, Kolagen Laut, Ekstrak Hazel, Air Bunga Mawar Centifolia, Ekstrak Daun Camellia Sinensis yang berfungsi untuk : ',
        item_descriptions: [
          'Menenangkan / membantu dengan cepat menenangkan kulit yang memerah akibat stimulasi',
          'Melindungi kulit dengan antioksidan ',
          'Bahan antioksidan alami membantu melindungi kulit dari dampak eksternal seperti sinar UV A UV B dan radikal bebas dan membuatnya lebih sehat.',
          'Menghidrasi kulit',
          'Moisturizer alami membantu menghidrasi kulit secara mendalam',
          'Merupakan perawatan wajah dan juga leher yang terdiri dari 2 lembar untuk merawat kulit di wajah dan leher.',
        ],
        duration: '20 Menit',
      },
      {
        treatment: 'Tea Tree Peel Off Mask',
        description:
          'Sebagai masker untuk memberikan kecerahan pada kulit wajah dan juga rasa kencang pada kulit',
        duration: '20 Menit',
      },
      {
        treatment: 'Algae Whitening Peel Off Mask',
        description:
          'Sebagai masker untuk mengatasi masalah kulit yang berjerawat',
        duration: '20 Menit',
      },
    ],
  },
  {
    name: 'Additional Treatment',
    image: require('../assets/images/aesthetic/additional.jpg'),
    items: [
      {
        treatment: 'Radiant Eye',
        description:
          'Perawatan mata yang berfungsi untuk memberi kelembaban, mengurangi lingkar hitam area mata, mengurangi kerutan halus, serta menunda proses penuaan dengan meregenerasi sel-sel sekitar area mata',
        duration: '15 Menit + Kombinasi Facial',
      },
      {
        treatment: 'Neck Decollete',
        description:
          'Perawatan leher dengan menggunakan peeling enzim untuk mencerahkan dan mengangkat sel kulit mati sekaligus menutrisi sel kulit dengan bahan aktif vit c yang bermanfaat untuk menangkal radikal bebas.',
        duration: '15 Menit + Kombinasi Facial',
      },
      {
        treatment: 'Lips Plumper',
        description:
          'Perawatan bibir dengan menggunakan bahan exfoliate guna mengangkat sel kulit mati area bibir, mencerahkan dan melembabkan area bibir, sekaligus mengatasi bibir pecah-pecah',
        duration: '15 Menit + Kombinasi Facial',
      },
      {
        treatment: 'Magic Acne Light',
        description:
          'Perawatan yang menggunakan 2 sinar yaitu sinar Red Light  dan Blue Light untuk mengatasi permasalahan Acne dan Kulit kemerahan',
        duration: '15 Menit + Kombinasi Facial',
      },
    ],
  },
]

export default aesthetic
