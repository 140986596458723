import { BtnSeeMore } from './index'
import Scroll from 'react-scroll'
import { BsArrowDownCircle } from 'react-icons/bs'

export default function RowDescription(props) {
  const ScrollLink = Scroll.Link

  const style = {
    ...props.style,
    backgroundImage: `url(${props.background})`,
    backgroundPosition: props.backgroundPosition ?? 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: props.height ?? '500px',
  }

  return (
    <section id="rowdescription" className={props.className} style={style}>
      <div id={props.id} className="d-flex align-items-center h-100">
        <div className="container">
          <div
            className={`row justify-content-${
              props.textPosition === 'right'
                ? 'end'
                : props.textPosition === 'center'
                ? 'center'
                : 'start'
            }`}
          >
            <div
              className={`${!props.notResponsive ? 'col-lg-6' : 'col-6'} text-${
                props.textColor
              } ${props.textCenter ? 'text-center' : ''}`}
            >
              <h1>{props.title}</h1>
              <p>{props.description}</p>
              {props.button &&
                (props.button.mode === 'link' ? (
                  <BtnSeeMore to={props.button.to} text={props.button.text} />
                ) : (
                  <ScrollLink
                    className={`d-flex flex-column align-items-center justify-content-center ${
                      props.textCenter ? 'text-center' : ''
                    }`}
                    to={props.button.to}
                    style={{ borderRadius: '0' }}
                  >
                    <BsArrowDownCircle className="mb-2" size={20} />
                    {props.button.text}
                  </ScrollLink>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
