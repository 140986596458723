export default function ModalDokter(props) {
  const { dokter } = props
  return (
    <div
      className="modal fade show"
      id="dokterModal"
      tabIndex="-1"
      aria-labelledby="dokterModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-end mb-3">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <img
              src={dokter.image}
              alt={dokter.name}
              style={{ width: '100%', height: '400px', objectFit: 'cover' }}
            />
            <div
              style={{
                height: '2px',
                width: '70%',
                backgroundColor: '#333',
                margin: '15px 0',
              }}
            ></div>
            <h4 className="fw-bold">{dokter.name}</h4>
            <h6 className="mb-4">{dokter.position}</h6>
            Pendidikan :
            <ul>
              {dokter.pendidikan.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            Organisasi :
            <ul>
              <li>{dokter.organisasi}</li>
            </ul>
            Achievements :
            <ul>
              {dokter.achievements.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
