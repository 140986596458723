import chat from '../../assets/images/icon/chat.png'
import appointment from '../../assets/images/icon/appointment.png'

export default function Contact(props) {
  return (
    <section id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 text-lg-end pe-lg-4">
            <img className="mb-3" src={chat} height="50" alt="chat" />
            <h4>Have some questions?</h4>
            <p>
              Kami selalu siap untuk menjawab seluruh pertanyaan yang kamu
              miliki. tentang pilihan yang terbaik untuk kulitmu
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=628112778779"
              target="_blank"
            >
              Buat Janji Konsultasi
            </a>
          </div>
          <div className="col-lg-5 mt-5 mt-lg-0 ps-lg-4">
            <img className="mb-3" src={appointment} height="50" alt="chat" />
            <h4>I’m ready to start my #GlowingJourney</h4>
            <p>
              Buat janji treatment mu sekarang! Tenang saja, kami akan selalu
              menemani di setiap langkahmu bersama kami.
            </p>
            <a
              href="https://api.whatsapp.com/send?phone=628112778779"
              target="_blank"
            >
              Reservasi Sekarang!
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
