const about_description = [
  {
    image: require('../assets/images/about/1.jpg'),
    title:
      'It’s not always about how we look, but it’s also about how we feel inside.',
    description:
      'Setiap dari kita pasti ingin menikmati hidup yang bahagia. Namun terkadang kehilangan kepercayaan diri bisa menurunkan kualitas kebahagiaan kita.',
  },
  {
    image: require('../assets/images/about/2.jpg'),
    title:
      'Looking good leads to feeling good, feeling good leads to empowerment',
    description:
      'Menunjukkan penampilan terbaik akan membawamu menjadi lebih percaya diri dan bisa mencapai kualitas kebahagiaan yang lebih tinggi. Itulah mengapa Lucent ada. Siapapun dirimu, apapun profesimu, dan bagaimanapun kehidupanmu, bersama Lucent, akan selalu ada cara untuk membuat dirimu menjadi lebih baik dan percaya diri.',
  },
  {
    image: require('../assets/images/about/3.jpg'),
    title:
      'We promise to deliver stunning results with personalized aesthetic treatment and care for a happier life.',
    description:
      'Dengan kombinasi dari dokter yang berpengalaman lebih dari 10 tahun serta teknologi estetika terkini, Lucent berkomitmen untuk mendampingimu memilih beauty treatment yang paling tepat. Kami tidak hanya menjawab apa yang kamu inginkan, melainkan memberi apa yang benar-benar kamu butuhkan. Dengan memberikan beauty advice dan treatment yang jelas, aman, dan disesuaikan dengan kebutuhan, tentu akan membantumu mendapatkan penampilan terbaik yang paling sesuai dengan karakter kulitmu. Bersama Lucent, raih penampilan terbaikmu untuk hidup yang penuh dengan kebahagiaan.',
    button: {
      type: 'scroll',
      to: 'header-team',
      text: 'Kenal lebih dekat dengan kami!',
    },
  },
]

export default about_description
