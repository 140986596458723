import { useState, useRef } from "react"
import emailjs from '@emailjs/browser'

export default function AppointmentModal() {
    const [isSending, setIsSending] = useState(false)
    const [status, setStatus] = useState('')
    const form = useRef();

    const sendMail = (e) => {
        e.preventDefault()
        setIsSending(true)

        emailjs
            .sendForm(
                'service_iykmkyl',
                'template_1kgarwn',
                form.current,
                '2MTeMlFJCvHeT1Qo7'
            )
            .then(
                (result) => {
                    setStatus(result.text)
                },
                (error) => {
                    setStatus(error.text)
                }
            )
            .finally(() => {
                setTimeout(() => {
                    setStatus('')
                }, 5000)
                setIsSending(false)
                form.current.reset()
            })
    }

    return (
        <div class="modal fade" id="appointmentModal" tabindex="-1" aria-labelledby="appointmentModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header align-items-start">
                        <div>
                            <h5 class="modal-title" id="appointmentModalLabel">APPOINTMENT ONLINE</h5>
                            <small className="mb-0">Buat Janji Temu secara Mudah dengan Appointment Online di Sini</small>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form ref={form} onSubmit={sendMail}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="branches" className="form-control-label">Branches</label>
                                        <select id="branches" name="branches" className="form-select">
                                            <option value="Lucent Aesthetics & Dermatology Clinic (Jl. Sisingamangaraja No 201B, Semarang)">Lucent Aesthetics & Dermatology Clinic (Jl. Sisingamangaraja No 201B, Semarang)</option>
                                            <option value="Lucent Aesthetics Clinic (Jl. Pandanaran I No. 19, Semarang)">Lucent Aesthetics Clinic (Jl. Pandanaran I No. 19, Semarang)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-control-label">Date</label>
                                        <input name="date" type="date" className="form-control" id="date" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phonenumber" className="form-control-label">Phone Number</label>
                                        <input name="number" type="number" className="form-control" id="phonenumber" required />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="fullname" className="form-control-label">Full Name</label>
                                        <input name="fullname" type="text" className="form-control" id="fullname" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-control-label">Email</label>
                                        <input name="email" type="email" className="form-control" id="email" required />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-control-label">Message</label>
                                        <textarea name="message" id="message" rows="5" className="form-control" required></textarea>
                                    </div>
                                    {status === 'OK' ? (
                                        <div
                                            className="alert alert-dark mb-3"
                                            style={{ borderRadius: '0' }}
                                            role="alert"
                                        >
                                            Terima kasih sudah membuat janji temu, silahkan tunggu balasan dari kami melalui email atau telephone
                                        </div>
                                    ) : (
                                        status !== '' && (
                                            <div
                                                className="alert alert-danger mb-3"
                                                style={{ borderRadius: '0' }}
                                                role="alert"
                                            >
                                                Mohon maaf sedang ada kesalahan, coba beberapa saat lagi
                                            </div>
                                        )
                                    )}
                                    <div className="d-block">
                                        <button className="btn btn-success rounded-pill w-100" style={{ backgroundColor: '#c15799', border: 'none' }} disabled={isSending ? true : false}>
                                            {isSending && (
                                                <div
                                                    className="spinner-border spinner-border-sm me-2"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></div>
                                            )}
                                            Make an appointment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}