import Scroll from 'react-scroll'

export default function RowDescription(props) {
  const ScrollLink = Scroll.Link
  return (
    <div
      className={`row align-items-center justify-content-around mt-lg-0 ${
        props.index === 0 ? 'mb-5' : 'my-5'
      } ${props.index % 2 === 0 ? 'flex-lg-row-reverse' : ''} ${
        props.className
      }`}
    >
      <div className="col-lg-6">
        <img src={props.image} alt={props.title} style={{ width: '100%' }} />
      </div>
      <div className="col-lg-6 mt-3 mt-lg-0">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
        {props.button && props.button.type === 'scroll' ? (
          <ScrollLink
            to={props.button.to}
            className="btn btn-outline-dark"
            style={{ borderRadius: '0' }}
          >
            {props.button.text}
          </ScrollLink>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
