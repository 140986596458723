import { Header, Description } from '../components/Contact/index'
import { PageTitle } from '../hooks/index'

export default function ContactUs(props) {
  PageTitle('Lucent Clinic - Contact Us')

  return (
    <div id="contact-us">
      <Header />
      <Description />
    </div>
  )
}
