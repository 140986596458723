const services = [
  {
    image: require('../assets/images/services/1.jpg'),
    title: 'Medical Treatments',
    description:
      'Lucent Clinic menawarkan berbagai medical treatment terbaik dengan teknologi tercanggih termasuk Pico Laser, Ultherapy, Thread Lift, dsb. Dengan keahlian >10 tahun dari Dokter ahli kami, Lucent Clinic memastikan untuk membantu memilih beauty treatment yang paling tepat. Kami tidak hanya menjawab apa yang kamu inginkan, melainkan memberi apa yang benar-benar kamu butuhkan.',
    button: {
      text: 'LIHAT TREATMENTS',
      to: '/services/medical-treatments',
      mode: 'link',
    },
  },
  {
    image: require('../assets/images/services/2.jpg'),
    title: 'Aesthetic Treatments',
    description:
      'Baik untuk yang akan menghadiri acara besar, melakukan perawatan kulit rutin, ataupun ingin me-time dengan relaksasi facial,  tim kami dapat membantu Anda untuk merencanakan rangkaian aesthetics treatment yang tepat.',
    button: {
      text: 'LIHAT TREATMENTS',
      to: '/services/aesthetic-treatments',
      mode: 'link',
    },
  },
  {
    image: require('../assets/images/services/3.jpg'),
    title: 'Products',
    description:
      'Dengan berbagai rangkaian produk yang sesuai dengan karakter kulit, Lucent Clinic juga siap untuk membantu #LucentFriends merawat kulit di luar beauty treatment. Konsultasikan dengan Dokter dan tim kami untuk dapatkan produk yang paling sesuai dan Anda butuhkan!.',
    button: {
      text: 'LIHAT PRODUCTS',
      to: '/services/products',
      mode: 'link',
    },
  },
]

export default services
