import { Content } from '../components/Service/index'
import { PageTitle } from '../hooks/index'
import PageHeader from '../components/PageHeader'

export default function Services(props) {
  PageTitle('Lucent Clinic - Services')

  return (
    <div id="services">
      <PageHeader
        title="Services"
        image={require('../assets/images/background/sort_about.jpg')}
      />
      <Content />
    </div>
  )
}
