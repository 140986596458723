import { PageTitle } from '../../hooks/index'
import PageHeader from '../../components/PageHeader'

export default function Products(props) {
  PageTitle('Lucent Clinic - Services - Products')

  return (
    <div id="products">
      <div id="header">
        <PageHeader
          title="Lucent Products"
          image={require('../../assets/images/background/products.jpg')}
          description="Informasi terkait produk dapat diketahui dengan menghubungi klinik kami di nomor berikut 0821 3483 1116 (WA)."
        />
      </div>
    </div>
  )
}
