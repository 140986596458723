import { RowDescription } from '../../components/index'
import aesthetic from '../../data/aesthetic'
import { PageTitle } from '../../hooks/index'
import PageHeader from '../../components/PageHeader'

export default function Aesthetic(props) {
  PageTitle('Lucent Clinic - Services - Aesthetic Treatments')

  return (
    <div id="aesthetic">
      <PageHeader
        title="Lucent Aesthetic Treatments"
        image={require('../../assets/images/background/aesthetic.png')}
      />
      {aesthetic.map((item, index) => {
        return (
          <div key={index} className="aesthetic-item">
            <RowDescription
              background={item.image}
              title={item.name}
              textColor="dark"
              textPosition="right"
              height="300px"
              className="aesthetic-category"
            />
            {item.items.map((item, index) => {
              return (
                <div key={index} className="content my-5">
                  <div className="container">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-lg-7">
                        <h1>{item.treatment}</h1>
                        <p>{item.description}</p>
                        {item.item_descriptions && (
                          <ul>
                            {item.item_descriptions.map((item, index) => {
                              return <li key={index}>{item}</li>
                            })}
                          </ul>
                        )}
                      </div>
                      <div className="col-lg-4">
                        <div className="d-flex align-items-center">
                          <img
                            src={require('../../assets/images/icon/durasi.png')}
                            style={{ height: '42px' }}
                            alt="durasi"
                          />
                          <div className="ms-2">
                            <small>Durasi Treatment</small>
                            <h5>{item.duration}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
