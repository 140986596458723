const signature = [
  {
    name: 'Lucent PicoWay Laser',
    image: require('../assets/images/signature/1.jpg'),
    link: 'services/medical-treatments',
  },
  {
    name: 'Lucent Ultherapy',
    image: require('../assets/images/signature/3.jpg'),
    link: 'services/medical-treatments',
  },
  {
    name: 'Lucent Peeling',
    image: require('../assets/images/signature/2.jpg'),
    link: 'services/medical-treatments',
  },
]

export default signature
