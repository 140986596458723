import { RowDescription } from '../components/index'
import { Hero, Description, Team } from '../components/About/index'
import { PageTitle } from '../hooks/index'

export default function AboutUs(props) {
  PageTitle('Lucent Clinic - About Us')

  return (
    <div id="about-us" className="about-us">
      <Hero />
      <Description />
      <RowDescription
        id="header-team"
        background={require('../assets/images/background/team.jpg')}
        title="Our Dedicated Experts"
        textColor="dark"
        textPosition="center"
        backgroundPosition="top"
        button={{ to: 'team', text: 'Scroll to Explore', mode: 'scroll' }}
        textCenter={true}
      />
      <Team />
    </div>
  )
}
