import { useState } from 'react'
import { dokter, team } from '../../data/team'
import ModalDokter from './ModalDokter'

export default function Team(props) {
  const [activeDokter, setActiveDokter] = useState(dokter[0])

  return (
    <section id="team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h1>Our Team</h1>
            <p>
              Seluruh dokter di Lucent adalah para profesional, dengan
              pengalaman lebih dari 10 tahun mempelajari berbagai teknik
              dermatologi baik dari dalam maupun luar negeri. Standar ini kami
              tetapkan agar kamu bisa mendapatkan pelayanan dan pengalaman
              terbaik selama waktumu bersama kami. Dokter kami tidak hanya akan
              menjawab apa yang kamu inginkan, tetapi juga memberikan apa yang
              kamu butuhkan.
            </p>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          {dokter.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => setActiveDokter(item)}
                data-bs-toggle="modal"
                data-bs-target="#dokterModal"
                className="col-6 col-lg-4"
              >
                <img className="card-dokter" src={item.image} alt={item.name} />
              </div>
            )
          })}
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-lg-8">
            <table className="table table-bordered">
              <thead>
                <tr className="text-center">
                  <th scope="col">Nama</th>
                  <th scope="col">Jabatan</th>
                </tr>
              </thead>
              <tbody>
                {team.map((item, index) => {
                  return (
                    <tr key={index} className="text-center">
                      <td>{item.name}</td>
                      <td>{item.position}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalDokter dokter={activeDokter} />
    </section>
  )
}
