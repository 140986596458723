const pico_laser = {
  image: require('../assets/images/medical/pico_laser.jpg'),
  name: 'Pico Laser Treatment',
  items: [
    {
      name: 'Lucent Pico Ultimate ',
      description: 'Prosedur dengan menggunakan alat laser ',
      durasi: '45 menit',
      fungsi: ['Mencerahkan', 'Menyamarkan flek', 'Mengencangkan'],
      prosedur:
        'Menggunakan tiga jenis handpiece yang berfungsi untuk mencerahkan  dan mengencangkan kulit wajah pasien serta membantu mengurangi pori-pori besar dan mengurangi flek',
    },
    {
      name: 'Lucent Pico Rejuvenesce',
      description:
        'Prosedur dengan menggunakan alat laser dengan panjang gelombang 1064 pico second',
      durasi: '45 menit',
      fungsi: ['Peremajaan', 'Mencerahkan'],
      prosedur:
        'Menggunakan dua jenis handpiece yang berfungsi untuk mencerahkan wajah dan membantu mengecilkan pori-pori besar',
    },
    {
      name: 'Lucent Pico Shine',
      description:
        'Prosedur dengan menggunakan alat laser gelombang 1064 pico second',
      durasi: '30 menit',
      fungsi: ['Mencerahkan', 'Meratakan warna kulit'],
      prosedur:
        'Menggunakan satu jenis handpiece yang berfungsi untuk mencerahkan wajah pasien tanpa downtime',
    },
    {
      name: 'Lucent Pico Anti Scar',
      description:
        'Prosedur mengurangi/memperbaiki tekstur kulit & luka/bekas jerawat',
      durasi: '40 menit',
      fungsi: ['Mengembalikan tekstur kulit', 'Memperbaiki luka bekas jerawat'],
      prosedur: 'Membantu mengurangi scar jerawat (bopeng)',
    },
    {
      name: 'Lucent Pico Tattoo Removal',
      description:
        'Prosedur menghilangkan tato dengan menggunakan alat laser dengan panjang gelombang 532 & 1064 pico second',
      durasi: '40 menit',
      fungsi: ['Menghilangkan tato berwarna tertentu'],
      prosedur: 'Mengurangi pigmen pada tato',
    },
  ],
}

const y_laser = {
  image: require('../assets/images/medical/y_laser.jpg'),
  name: 'Y Laser Treatment',
  items: [
    {
      name: 'Lucent Y Laser Fight Mella',
      description: 'Prosedur laser dengan panjang gelombang 577 nanometer',
      durasi: '45 menit',
      fungsi: [
        'Mengatasi Flek',
        'Mengatasi Pigmentasi',
        'Menonaktifkan pembuluh darah',
      ],
      prosedur:
        'Membersihkan wajah pasien dan diolesi gel seluruh wajah sebagai perantara tindakan laser',
    },
    {
      name: 'Lucent Y Laser Fight Acne',
      description: 'Prosedur laser dengan panjang gelombang 577 nanometer',
      durasi: '30 - 45 menit',
      fungsi: ['Mengatasi jerawat yang meradang'],
      prosedur:
        'Membersihkan wajah pasien dan diolesi gel seluruh wajah sebagai perantara tindakan laser',
    },
    {
      name: 'Lucent Sparkling Laser',
      description: 'Prosedur laser dengan panjang gelombang 577 nanometer',
      durasi: '30 menit',
      fungsi: ['Mencerahkan', 'Meratakan warna kulit'],
      prosedur: 'diaplikasikan laser tanpa rasa sakit sehingga pasien nyaman',
    },
    {
      name: 'Lucent Y Laser Relieve Redness',
      description: 'Prosedur laser dengan panjang gelombang 577 nanometer',
      durasi: '30 - 45 menit',
      fungsi: ['Mengurangi kemerahan pada kulit'],
      prosedur:
        'Membersihkan wajah pasien dan diolesi gel seluruh wajah sebagai perantara tindakan laser',
    },
    {
      name: 'Lucent Y Laser Tel',
      description: 'Prosedur laser dengan panjang gelombang 577 nanometer',
      durasi: '30 - 45 menit',
      fungsi: ['Mengatasi bercak kemerahan akibat pembuluh darah'],
      prosedur:
        'pengaplikasian laser menyusuri telangiektasis (pembuluh darah yang melebar)',
    },
  ],
}

const fractional_laser = {
  image: require('../assets/images/medical/fractional_laser.jpg'),
  name: 'Fractional Laser Treatment',
  items: [
    {
      name: 'Lucent Secret Fractional Laser',
      description: 'Prosedur laser dengan menggunakan alat laser',
      durasi: '30 - 45 menit',
      fungsi: ['Mengecilkan pori-pori', 'Mencerahkan'],
      prosedur:
        'Pasien dibersihkan wajah lalu di anastesi selama kurang lebih 20 menit, baru dilakukan tindakan laser',
    },
    {
      name: 'Lucent Secret Electra',
      description: 'Prosedur microneedling yang dialiri radio frequency',
      durasi: '30 - 45 menit',
      fungsi: [
        'Mengecilkan pori-pori',
        'Pengencangan',
        'Mencerahkan',
        'Menghaluskan tekstur kulit',
      ],
      prosedur:
        'Pasien dibersihkan wajahnya dan dianastesi kurang lebih 20 menit lalu dilakukan tindakan',
    },
  ],
}

const radio_frequency = {
  image: require('../assets/images/medical/radio_frequency.jpg'),
  name: 'Radio Frequency',
  items: [
    {
      name: 'Red Carpet Nano Peel',
      description: 'Prosedur mechanical peeling dan radio frekuensi',
      durasi: '20 menit',
      fungsi: ['Pengencangan', 'Mencerahkan', 'Mengangkat sel kulit mati'],
      prosedur:
        'Pasien dibersihkan wajahnya kemudian dilakukan tindakan pertama yaitu mechanical peeling dengan mikrodermabrasi kemudian dilanjutkan dengan pengeaplikasi serum dengan alat RF',
    },
    {
      name: 'Slimming Body RF',
      description: 'Prosedur dengan menggunakan alat radio frekuensi',
      durasi: '10 - 20 menit',
      fungsi: ['Pengencangan', 'Meluruhkan lemak'],
      prosedur:
        'Area yang akan di treatment diolesi gel lalu dilakukan treatment',
    },
    {
      name: 'Radio Frequency for Face',
      description: 'Prosedur dengan menggunakan alat radio frekuensi',
      durasi: '10 - 20 menit',
      fungsi: ['Pengencangan', 'Meluruhkan lemak'],
      prosedur:
        'Kulit wajah dibersihkan kemudian diaplikasikan gel pada area yang dilakukan Rf seperti pipi, double chin, dan mata',
    },
  ],
}

const rejuran_injections = {
  image: require('../assets/images/medical/rejuran_injections.jpg'),
  name: 'Rejuran Injections',
  items: [
    {
      name: 'Lucent Rejuran Repair',
      description: 'Prosedur treatment injectable yang mengandung DNA Salmon',
      durasi: '30 - 45 menit',
      fungsi: [
        'Memperbaiki tekstur kulit',
        'Menstimulasi kolagen',
        'Meningkatkan elastisitas kulit',
      ],
      prosedur:
        'Seluruh wajah dibersihkan kemudian di anestesi selama kurang lebih 20 menit kemudian DNA Salmon disuntikkan di seluruh wajah',
    },
    {
      name: 'Lucent Rejuran Eye',
      description: 'Prosedur treatment injectable yang mengandung DNA Salmon',
      durasi: '30 - 45 menit',
      fungsi: [
        'Mengatasi kerutan halus disekitar mata',
        'Meningkatkan elastisitas kulit',
      ],
      prosedur:
        'Seluruh wajah dibersihkan kemudian di anestesi selama kurang lebih 20 menit kemudian DNA Salmon disuntikkan di bawah mata',
    },
    {
      name: 'Lucent Rejuran Scar Repair',
      description: 'Prosedur treatment injectable yang mengandung DNA Salmon',
      durasi: '30 - 45 menit',
      fungsi: [
        'Memperbaiki tekstur kulit ',
        'Mengatasi bekas luka bopeng akibat jerawat',
      ],
      prosedur:
        'Seluruh wajah dibersihkan kemudian di anestesi selama kurang lebih 20 menit kemudian DNA Salmon disuntikkan di area scar (bopeng)',
    },
  ],
}

const peeling = {
  image: require('../assets/images/medical/peeling.jpg'),
  name: 'Peeling',
  items: [
    {
      name: 'Lucent Peeling Red Carpet',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '15 - 20 menit',
      fungsi: [
        'Mengangkat sel kulit mati',
        'Meratakan warna kulit',
        'Melembapkan',
        'Mencerahkan',
      ],
      prosedur:
        'Pasien dibersihkan wajahnya kemudian dilakukan tindakan pertama yaitu mechanical peeling dengan mikrodermabrasi kemudian dilanjutkan dengan pengeaplikasi serum.',
    },
    {
      name: 'Lucent Peeling Instant Glow',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '10 - 15 menit',
      fungsi: [
        'Mengangkat sel kulit mati',
        'Meratakan warna kulit',
        'Mencerahkan',
      ],
      prosedur:
        'Wajah dibersihkan kemudian oleskan cairan peeling setelah beberapa menit dinetralisir dan dilanjutkan dengan pembilasan dengan air mengalir. Tahap akhir di kompress dengan handuk dingin',
    },
    {
      name: 'Lucent Peeling Shiny ',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '10 - 15 menit',
      fungsi: [
        'Mengangkat sel kulit mati',
        'Meratakan warna kulit',
        'Mengatasi hiperpigmentasi',
      ],
      prosedur:
        'Wajah dibersihkan kemudian oleskan cairan peeling dan dibilas 4 jam setelahnya',
    },
    {
      name: 'Lucent Peeling Magic',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '20 - 25 menit',
      fungsi: [
        'Mengangkat sel kulit mati',
        'Meratakan warna kulit',
        'Mengatasi pigmentasi',
      ],
      prosedur:
        'Wajah dibersihkan kemudian diolesi dengan bahan campuran khusus dan di aplikasikan ke wajah dengan massage ringan. Didiamkan beberapa menit kemudian dioleskan Gel setelah itu dibilas',
    },
    {
      name: 'Lucent Peeling Radiance ',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '10 - 15 menit',
      fungsi: ['Mengeringkan jerawat'],
      prosedur:
        'Wajah dibersihkan kemudian oleskan cairan peeling setelah beberapa menit dinetralisir dan dilanjutkan dengan pembilasan dengan air mengalir. Tahap akhir di kompress dengan handuk dingin',
    },
    {
      name: 'Lucent Peeling Lightening Body',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '5 - 10 menit',
      fungsi: [
        'Mengangkat sel kulit mati',
        'Meratakan warna kulit',
        'Mencerahkan',
      ],
      prosedur:
        'Bagian badan tertentu dibersihkan kemudian oleskan cairan peeling setelah beberapa menit dinetralisir dan dilanjutkan dengan pembilasan dengan air mengalir. Tahap akhir di kompress dengan handuk dingin',
    },
    {
      name: 'Lucent Peeling Glycolic ',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '5 - 10 menit',
      fungsi: ['Mengangkat sel kulit mati', 'Mencerahkan'],
      prosedur:
        'Wajah dibersihkan kemudian oleskan cairan peeling setelah beberapa menit dinetralisir dan dilanjutkan dengan pembilasan dengan air mengalir. Tahap akhir di kompress dengan handuk dingin',
    },
    {
      name: 'Lucent Peeling Eye Brightening',
      description: 'Prosedur treatment mechanical peeling',
      durasi: '5 - 10 menit',
      fungsi: ['Mengurangi mata panda', 'Mencerahkan'],
      prosedur:
        'Bagian sekitar mata dibersihkan kemudian oleskan cairan peeling setelah beberapa menit dinetralisir dan dilanjutkan dengan pembilasan dengan air mengalir. Tahap akhir di kompress dengan handuk dingin',
    },
  ],
}

const infusions = {
  image: require('../assets/images/medical/infusions.jpg'),
  name: 'Infusions',
  items: [
    {
      name: 'Lucent Infusions Ultimate Immune Booster',
      description:
        'Prosedur treatment infusion menggunakan beberapa vitamin dan campuran koktail yang lengkap',
      durasi: '10 - 30 menit',
      fungsi: [
        'Mencerahkan',
        'Meningkatkan daya imun tubuh',
        'Membantu kolagenesis',
        'Melembapkan kulit',
      ],
      prosedur:
        'Pemberian Obat dan Vitamin yang dilakukan secara langsung melalui pembuluh darah',
    },
    {
      name: 'Lucent Infusions Skin Brightening',
      description:
        'Prosedur treatment infusion menggunakan beberapa vitamin dan satu campuran koktail',
      durasi: '10 - 20 menit',
      fungsi: ['Mencerahkan', 'Melembapkan kulit'],
      prosedur:
        'Pemberian Obat dan Vitamin yang dilakukan secara langsung melalui pembuluh darah',
    },
    {
      name: 'Lucent Infusions Skin Repair',
      description: 'Prosedur treatment injectable',
      durasi: '5 menit',
      fungsi: ['Mencerahkan'],
      prosedur:
        'Pemberian Obat dan Vitamin yang dilakukan secara langsung melalui pembuluh darah',
    },
    {
      name: 'Lucent Infusions Brightening',
      description: 'Prosedur treatment injectable',
      durasi: '5 menit',
      fungsi: ['Mencerahkan'],
      prosedur:
        'Pemberian Obat dan Vitamin yang dilakukan secara langsung melalui pembuluh darah',
    },
  ],
}

const ultheraphy = {
  image: require('../assets/images/medical/ultherapy.jpg'),
  name: 'Ultherapy',
  items: [
    {
      name: 'Lucent 3 Ultherapy Layer Full Face & Neck',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '60 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent 3 Ultherapy Face & Neck',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '60 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent Ultherapy Face Contour',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '60 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent Ultherapy Shape 1',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '60 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent Ultherapy Shape 2',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '45 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent Ultherapy Face',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '45 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent Ultherapy Smooth',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '45 menit',
      fungsi: ['Mengencangkan wajah', 'Membentuk kolagen'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
    {
      name: 'Lucent Ultherapy For Eye',
      description:
        'Prosedur tindakan perawatan wajah dengan menggunakan alat ultherapy',
      durasi: '45 menit',
      fungsi: ['Mengencangkan bagian mata dan dahi'],
      prosedur:
        'Wajah dibersihkan dahulu kemudian diolesi krim anestesi tunggu selama 1 jam, kemudian dibersihkan krim anasteris lalu dilakukan mapping setelah itu melakukan tindakan Ultherapy pada bagian yang sudah ditentukan',
    },
  ],
}

const thread_lift = {
  image: require('../assets/images/medical/thread_lift.jpg'),
  name: 'Thread Lift',
  items: [
    {
      name: 'Lucent Aptos EV HA',
      description:
        'Prosedur tindakan yang menggunakan bahan benang yang dapat diserap oleh tubuh',
      durasi: '60 menit',
      fungsi: ['Membentuk wajah lebih v-shape'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'V',
      description:
        'Prosedur tindakan yang menggunakan bahan benang yang dapat diserap kulit',
      durasi: '60 menit',
      fungsi: ['Membentuk wajah lebih v-shape'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'Double Chin Lift',
      description:
        'Prosedur tindakan yang menggunakan bahan benang pada bagian double chin',
      durasi: '60 menit',
      fungsi: ['Mengurangi kulit yang terlihat double chin'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: '2G',
      description:
        'Prosedur tindakan yang menggunakan bahan benang yang dapat diserap oleh tubuh',
      durasi: '60 menit',
      fungsi: ['Membentuk wajah lebih v-shape'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'Excellent Total Lift',
      description:
        'Prosedur tindakan yang menggunakan bahan benang untuk membentuk wajah',
      durasi: '60 menit',
      fungsi: ['Membentuk wajah lebih v-shape'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'Lux Thread',
      description:
        'Prosedur tindakan yang menggunakan bahan benang untuk membentuk wajah',
      durasi: '1 jam',
      fungsi: ['Membentuk wajah'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'Ultimate COG Thread Lift',
      description:
        'Prosedur tindakan yang menggunakan bahan benang untuk membentuk wajah',
      durasi: '1 jam',
      fungsi: ['Membentuk wajah', 'Memberikan kolagen'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'Hiko Nose',
      description:
        'Perawatan kecantikan untuk mempertegas batang hidung dengan benang khusus',
      durasi: '45 menit',
      fungsi: ['Mempertegas batang hidung'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
    {
      name: 'Hiko Nose Short',
      description:
        'Perawatan kecantikan untuk merampingkan cuping hidung dengan benang khusus',
      durasi: '45 menit',
      fungsi: ['Merampingkan cuping hidung'],
      prosedur:
        'Wajah dibersihkan kemudian dilakukan mapping lalu di area wajah di setting steril kemudian dilakukan anastesi inject dan dilakukan Thread Lift',
    },
  ],
}

const dermal_filler = {
  image: require('../assets/images/medical/dermal_filler.jpg'),
  name: 'Dermal Filler',
  items: [
    {
      name: 'Xelaridem',
      description:
        'Perawatan wajah yang dirancang untuk memperbaiki struktur kulit',
      durasi: '60 menit',
      fungsi: ['Memperbaiki tekstur kulit'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: 'Volite 1 ml',
      description:
        'Perawatan wajah yang dirancang untuk meningkatkan kualitas kulit',
      durasi: '45 menit',
      fungsi: ['Mengkondisikan kulit agar memiliki tekstur sempurna'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: 'Redensity  ',
      description:
        'Perawatan wajah yang dirancang untuk memperbaiki struktur kulit',
      durasi: '60 menit',
      fungsi: ['Memperbaiki tekstur kulit'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: 'Belotero Balance 1 ml',
      description:
        'Perawatan wajah yang dirancang untuk memperbaiki bentuk wajah',
      durasi: '45 menit',
      fungsi: ['Membentuk bagian wajah tertentu'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: 'Belotero Intense 1 ml',
      description:
        'Perawatan wajah yang dirancang untuk memperbaiki bentuk wajah',
      durasi: '45 menit',
      fungsi: ['Membentuk bagian wajah tertentu'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: 'Radiesse 1.5 ml',
      description:
        'Perawatan wajah yang dirancang untuk memperbaiki bentuk wajah',
      durasi: '45 menit',
      fungsi: ['Membentuk bagian wajah tertentu'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: 'Double Skin Plumper',
      description:
        'Perawatan wajah yang dirancang untuk memperbaiki struktur wajah',
      durasi: '45 menit',
      fungsi: ['Memberikan nutrisi pada kulit dan membentuk wajah'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
    {
      name: '',
      description:
        'Perawatan wajah yang dirancang khusus untuk memberikan efek kenyal dan hidrasi yang maksimal',
      durasi: '45 menit',
      fungsi: ['Memberikan nutrisi pada kulit dan membentuk wajah'],
      prosedur:
        'Wajah dibersihkan lalu di anestesi selama 20 menit kemudian di suntikan di area yang sudah ditentukan',
    },
  ],
}

const rich_plasma = {
  image: require('../assets/images/medical/rich_plasma.jpg'),
  name: 'Rich Plasma',
  items: [
    {
      name: 'Lucent Rich Plasma',
      description: 'Prosedur tindakan perawatan menggunakan PRP',
      durasi: '60 menit',
      fungsi: ['Memberikan kolagenosis', 'Mengecilkan pori-pori'],
      prosedur:
        'Darah vena pasien diambil sebanyak 10 ml kemudian area wajah dibersihkan dan dilanjutkan anestesi. Kemudian darah yang diambil dilakukan sentrifugasi untuk mengambil plasma lalu dimasukkan kulit wajah dengan microneedle',
    },
  ],
}

const injections = {
  image: require('../assets/images/medical/injections.jpg'),
  name: 'Injections',
  items: [
    {
      name: 'Baby Glass Skin',
      description:
        'Prosedur dengan mengkombinasikan bahan rejuran dan redensity',
      durasi: '60 menit',
      fungsi: ['Memperbaiki struktur kulit', 'Mengecilkan pori-pori'],
      prosedur: 'Melakukan injeksi rejuran dan redensity ke seluruh wajah',
    },
    {
      name: 'Glow Tox',
      description:
        'Prosedur yang menggunakan toxin botulinum dengan pengenceran tertentu',
      durasi: '30 menit',
      fungsi: [
        'Pengencangan kulit ',
        'Mengurangi kerutan halus',
        'Mengecilkan pori-pori besar',
      ],
      prosedur:
        'Wajah dibersihkan dan dilakukan anastesi 20 menit kemudian dilakukan injeksi Toxin Botulinum ke area wajah yang sudah ditentukan',
    },
    {
      name: 'Eye Bright Tox',
      description: 'Prosedur dengan menggunakan bahan rejuran eye',
      durasi: '60 menit',
      fungsi: ['Mencerahkan bagian kelopak dan bawah mata'],
      prosedur: 'Melakukan injeksi di bawah mata dan kelopak',
    },
    {
      name: 'Melasma Meso Anti Mela',
      description:
        'Prosedur tindakan injeksi dengan menggunakan bahan yang dapat menyamarkan melasma',
      durasi: '45 menit',
      fungsi: ['Menyamarkan melasma'],
      prosedur: 'Melakukan injeksi di bagian melasma',
    },
    {
      name: 'Melasma Fight',
      description:
        'Prosedur tindakan injeksi dengan menggunakan bahan yang dapat menyamarkan melasma',
      durasi: '45 menit',
      fungsi: ['Menyamarkan melasma'],
      prosedur: 'Melakukan injeksi di bagian melasma',
    },
    {
      name: 'Lippomelt Face 1',
      description: 'Prosedur tindakan dengan cara pembakaran lemak',
      durasi: '45 menit',
      fungsi: ['Membakar lemak di bagian tertentu'],
      prosedur:
        'Melakukan injeksi bagian berlemak dengan menggunakan bahan tertentu',
    },
    {
      name: 'Lippomelt Face 2',
      description: 'Prosedur tindakan dengan cara pembakaran lemak',
      durasi: '45 menit',
      fungsi: ['Membakar lemak di bagian tertentu'],
      prosedur:
        'Melakukan injeksi bagian berlemak dengan menggunakan bahan tertentu',
    },
    {
      name: 'Lippomelt ABD',
      description: 'Prosedur tindakan dengan cara pembakaran lemak',
      durasi: '45 menit',
      fungsi: ['Membakar lemak di bagian tertentu'],
      prosedur:
        'Melakukan injeksi bagian berlemak dengan menggunakan bahan tertentu',
    },
    {
      name: 'Lippomelt Thigh',
      description: 'Prosedur tindakan dengan cara pembakaran lemak',
      durasi: '45 menit',
      fungsi: ['Membakar lemak di bagian tertentu'],
      prosedur:
        'Melakukan injeksi bagian berlemak dengan menggunakan bahan tertentu',
    },
    {
      name: 'HA Lyse',
      description:
        'Prosedur tindakan dengan cara injeksi bagian wajah tertentu',
      durasi: '45 menit',
      fungsi: ['Menghancurkan filler agar lebih encer'],
      prosedur:
        'Melakukan injeksi dengan bahan sebagai penghilang filler pada bagian tertentu',
    },
    {
      name: 'Acne',
      description:
        'Prosedur tindakan dengan cara injeksi suatu bahan pada bagian wajah yang berjerawat',
      durasi: '10 menit',
      fungsi: ['Mengurangi peradangan pada jerawat'],
      prosedur: 'Melakukan injeksi ke bagian wajah yang berjerawat',
    },
    {
      name: 'Profhillo',
      description:
        'Prosedur tindakan dengan cara injeksi yang aman untuk mengatasi permasalahan kulit yang kering, Menua, dan Kendor',
      durasi: '45 menit',
      fungsi: [
        'Stimulasi Collagen',
        'Menambah kadar air di kulit',
        'Mencegah Penuaan Dini',
        'Mengencangkan Kulit',
      ],
      prosedur:
        'Melakukan injeksi ke bagian wajah di titik - titik tertentu. Dibutuhkan anastesi terlebih dahulu sebelum melakukan tindakan ini.',
    },
    {
      name: 'Lucent Pink Booster',
      description:
        'Prosedur tindakan dengan cara injeksi bagian wajah tertentu',
      durasi: '20 menit',
      fungsi: ['Merangsang colagen', 'Mencerahkan Wajah'],
      prosedur: 'Melakukan injeksi ke seluruh bagian wajah',
    },
  ],
}

const medical = [
  pico_laser,
  y_laser,
  fractional_laser,
  radio_frequency,
  rejuran_injections,
  peeling,
  infusions,
  ultheraphy,
  thread_lift,
  dermal_filler,
  rich_plasma,
  injections,
]

export default medical
