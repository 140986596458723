import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  Home,
  AboutUs,
  ContactUs,
  Services,
  Products,
  Aesthetic,
  Medical,
} from './pages/index'
import { ScrollToTop, useWithPageView } from './hooks/index'
import { Navbar, Join, ButtonToTop } from './components/index'
import './assets/css/style.css'
import AppointmentModal from './components/AppointmentModal'
import ButtonAppointmentWa from './components/ButtonAppointmentWa'
import Appointment from './pages/Appointment'

function Routess() {
  useWithPageView()

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/appointment" element={<Appointment />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/services" element={<Services />} />
      <Route path="/services/products" element={<Products />} />
      <Route path="/services/aesthetic-treatments" element={<Aesthetic />} />
      <Route path="/services/medical-treatments" element={<Medical />} />
    </Routes>
  )
}

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ButtonToTop />
      <ButtonAppointmentWa />
      <Navbar />
      <Routess />
      <Join />
      <AppointmentModal />
    </BrowserRouter>
  )
}

export default App
