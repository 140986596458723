import { useState, useEffect } from 'react'
import { BiUpArrowAlt } from 'react-icons/bi'

export default function ButtonToTop(props) {
  const [isTop, setIsTop] = useState(true)

  const checkNavbarIsTop = () => {
    if (window.scrollY >= 300) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
  }

  useEffect(() => {
    checkNavbarIsTop()
    window.addEventListener('scroll', checkNavbarIsTop)
  }, [])

  return (
    <button
      onClick={() => window.scrollTo(0, 0)}
      className={`button-to-top ${isTop ? 'd-none' : ''}`}
    >
      <BiUpArrowAlt className="icon" />
    </button>
  )
}
