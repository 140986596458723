const hero = [
  {
    image: require('../assets/images/hero/1.jpg'),
  },
  {
    image: require('../assets/images/hero/2.jpg'),
  },
  {
    image: require('../assets/images/hero/3.jpg'),
  },
]

export default hero
