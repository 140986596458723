import { BiChevronRight } from 'react-icons/bi'

export default function Fact(props) {
  const style = {
    backgroundImage: `url(${require('../../assets/images/background/fact.jpg')})`,
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  return (
    <section id="fact" style={style}>
      <div className="d-flex align-items-center h-100">
        <div className="container text-center">
          <h1>Sekilas tentang Lucent</h1>
          <div className="row mt-3 mt-lg-5">
            <div className="col-lg-4 mt-5 mt-lg-0">
              <h4 className="mb-0">Pengalaman Dokter</h4>
              <div className="d-flex align-items-center justify-content-center">
                <span className="mt-3" style={{ fontSize: '50px' }}>
                  <BiChevronRight />
                </span>
                <h1 style={{ fontSize: '100px' }}>
                  10
                  <span style={{ fontSize: '32px' }}>Tahun</span>
                </h1>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0 center">
              <h4 className="mb-0">No. of successful treatments</h4>
              <div className="d-flex align-items-center justify-content-center">
                <span className="mt-3" style={{ fontSize: '50px' }}>
                  <BiChevronRight />
                </span>
                <h1 style={{ fontSize: '100px' }}>250</h1>
              </div>
            </div>
            <div className="col-lg-4 mt-5 mt-lg-0">
              <h4 className="mb-0">Happy Customers</h4>
              <div className="d-flex align-items-center justify-content-center">
                <span className="mt-3" style={{ fontSize: '50px' }}>
                  <BiChevronRight />
                </span>
                <h1 style={{ fontSize: '100px' }}>500</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
