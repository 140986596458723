import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import hero from '../../data/hero'

export default function Hero(props) {
  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1500,
    draggable: false,
    pauseOnHover: false,
  }
  return (
    <Slider {...settings}>
      {hero.map((item, index) => {
        return (
          <section key={index} id="hero">
            <img src={item.image} style={{ width: '100%' }} alt="" />
          </section>
        )
      })}
    </Slider>
  )
}
