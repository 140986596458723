import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineCalendar } from 'react-icons/ai'

export default function Navbar(props) {
  const [navActive, setNavActive] = useState(false)
  const [isTop, setIsTop] = useState(true)

  const checkNavbarIsTop = () => {
    if (window.scrollY >= 60) {
      setIsTop(false)
    } else {
      setIsTop(true)
    }
  }

  useEffect(() => {
    checkNavbarIsTop()
    window.addEventListener('scroll', checkNavbarIsTop)
  }, [])

  return (
    <>
      <nav
        className={`navbar-mobile d-lg-none fixed-top ${!isTop ? 'bg-light' : ''
          } ${navActive ? 'active' : ''}`}
      >
        <div className="container d-flex flex-column algin-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-between">
            <Link
              to="/"
              className="navbar-brand"
              onClick={() => setNavActive(false)}
            >
              <img
                src={require('../assets/images/logo.png')}
                height="50"
                alt="Logo Lucent Clinic Indonesia"
                className="mb-2"
              />
            </Link>
            <button
              className="burger"
              onClick={() =>
                navActive ? setNavActive(false) : setNavActive(true)
              }
            >
              <GiHamburgerMenu size={30} />
            </button>
          </div>
          <ul>
            <li>
              <Link to="/about-us" onClick={() => setNavActive(false)}>
                About Us
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/services" onClick={() => setNavActive(false)}>
                Services
              </Link>
              <ul>
                <li>
                  <Link
                    to="/services/medical-treatments"
                    onClick={() => setNavActive(false)}
                  >
                    Medical
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/aesthetic-treatments"
                    onClick={() => setNavActive(false)}
                  >
                    Aesthetic
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/products"
                    onClick={() => setNavActive(false)}
                  >
                    Product
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/contact-us" onClick={() => setNavActive(false)}>
                Contact Us
              </Link>
            </li>
            <li>
              <a role="button" data-bs-toggle="modal" data-bs-target="#appointmentModal" className="d-flex align-items-center" style={{ backgroundColor: '#c15799', color: '#fff', padding: '5px 10px', borderRadius: '5px' }}>
                <img src={require('../assets/images/icon/calendar.png')} height="15" className="me-2" />
                Make Appointment Now
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <nav
        className={`navbar d-none d-lg-block fixed-top ${!isTop ? 'bg-light' : ''
          } ${navActive ? 'active' : ''}`}
      >
        <div className="container d-flex flex-column algin-items-center justify-content-center">
          <button
            className="burger"
            onClick={() =>
              navActive ? setNavActive(false) : setNavActive(true)
            }
          >
            <GiHamburgerMenu size={30} />
          </button>
          <Link
            to="/"
            className="navbar-brand"
            onClick={() => setNavActive(false)}
          >
            <img
              src={require('../assets/images/logo.png')}
              height="50"
              alt="Logo Lucent Clinic Indonesia"
              className="mb-2"
            />
          </Link>
          <ul className={`${!isTop ? 'not-top' : ''} ${!navActive ? 'align-items-center' : ''}`}>
            <li>
              <Link to="/about-us" onClick={() => setNavActive(false)}>
                About Us
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/services" onClick={() => setNavActive(false)}>
                Services
              </Link>
              <ul>
                <li>
                  <Link
                    to="/services/medical-treatments"
                    onClick={() => setNavActive(false)}
                  >
                    Medical
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/aesthetic-treatments"
                    onClick={() => setNavActive(false)}
                  >
                    Aesthetic
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/products"
                    onClick={() => setNavActive(false)}
                  >
                    Product
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/contact-us" onClick={() => setNavActive(false)}>
                Contact Us
              </Link>
            </li>
            <li>
              <a role="button" data-bs-toggle="modal" data-bs-target="#appointmentModal" className="d-flex align-items-center" style={{ backgroundColor: '#c15799', color: '#fff', padding: '5px 10px', borderRadius: '5px' }}>
                <img src={require('../assets/images/icon/calendar.png')} height="15" className="me-2" />
                Make Appointment Now
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}
