import { RowDescription } from '../components/index'
import { Hero, Fact, Contact, Signature } from '../components/Home/index'
import { PageTitle } from '../hooks/index'

export default function Home(props) {
  PageTitle('Lucent Clinic')

  return (
    <>
      <Hero />
      <RowDescription
        background={require('../assets/images/background/sort_about.jpg')}
        title="Personalized Aesthetic Treatment and Care"
        description="Dengan kombinasi dari dokter yang berpengalaman lebih dari 10 tahun serta teknologi estetika terkini, Lucent Clinic berkomitmen untuk mendampingimu memilih beauty treatment yang paling tepat. Kami tidak hanya menjawab apa yang kamu inginkan, melainkan memberi apa yang benar-benar kamu butuhkan."
        button={{ to: '/about-us', text: 'See More', mode: 'link' }}
        textColor="dark"
        textPosition="right"
        style={{ marginTop: '-6px', zIndex: -1 }}
        className="personalized"
      />
      <Fact />
      <RowDescription
        background={require('../assets/images/background/priority.jpg')}
        title="Your Safety is Our First Priority"
        description="Kesehatan dan keamanan adalah yang terpenting. Untuk itu kami telah menyiapkan serangkaian prosedur keamanan untuk menjagamu tetap aman dan dapat menikmati waktu bersama kami."
        textColor="dark"
        textPosition="left"
        backgroundPosition="top"
        className="safety"
      />
      <Signature />
      <Contact />
    </>
  )
}
