import data from '../../data/signature'
import { BtnSeeMore } from '../index'

export default function Signature(props) {
  const line = {
    height: '2px',
    width: '80px',
    backgroundColor: '#333',
  }
  return (
    <section id="signature">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center mb-4 mb-lg-5">
            <div className="d-flex justify-content-center align-items-center">
              <div style={line} />
              <h1 className="mx-3">Lucent Signature</h1>
              <div style={line} />
            </div>
          </div>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="col-lg-4 card bg-transparent"
                style={{ borderRadius: '0' }}
              >
                <div className="card-header p-0 mt-3">
                  <img
                    src={item.image}
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                    alt={item.name}
                  />
                </div>
                <div className="card-body text-center">
                  <h4>{item.name}</h4>
                  <BtnSeeMore to={item.link} text="See More" justify="center" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
