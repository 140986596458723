import { FiPhoneCall } from 'react-icons/fi'
import { AiOutlineMail } from 'react-icons/ai'

export default function Description(props) {
  return (
    <div id="description" className="container py-5">
      <div className="row">
        <div className="col-lg-6">
          <h1 className="mb-4">Lucent Aesthetics & Dermatology Clinic</h1>
          <p style={{ fontSize: '20px' }}>
            Jl. Sisingamangaraja No. 201B, RW.2,
            <br /> Wonotingal, Kec. Candisari,
            <br /> Kota Semarang, Jawa Tengah 50252
          </p>
          <p
            className="d-flex align-items-center mb-0"
            style={{ fontSize: '20px' }}
          >
            <FiPhoneCall className="me-2" />
            <b>024-76420708</b>
          </p>
          <p
            className="d-flex align-items-center mb-0"
            style={{ fontSize: '20px' }}
          >
            <FiPhoneCall className="me-2" />
            <b>0811 2778 779</b>
          </p>
          <p className="d-flex align-items-center" style={{ fontSize: '20px' }}>
            <AiOutlineMail className="me-2" />
            <b>lucentclinicid@gmail.com</b>
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=628112778779"
            className="btn btn-outline-dark mt-2 px-4 py-2"
            style={{ borderRadius: '0' }}
          >
            Hubungi Kami
          </a>
        </div>
        <div className="col-lg-6 mt-4 mt-lg-0 p-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.9483744895856!2d110.41871695061793!3d-7.015354270644717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708bed0cd0e35d%3A0x6e70e1cf119ea23e!2sLucent%20Aesthetics%20%26%20Dermatology%20Clinic!5e0!3m2!1sen!2snl!4v1659412472787!5m2!1sen!2snl"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{
              width: '100%',
              height: '350px',
              padding: '0 !important',
              margin: '0',
            }}
            title="Lucent Map"
          ></iframe>
        </div>
      </div>
    </div>
  )
}
