const dokter = [
  {
    image: require('../assets/images/dokter/lidya.jpg'),
    name: 'dr. Lidya Barasjid, Dipl.AAAM',
    position: 'Founder & Consultant Doctor',
    pendidikan: [
      'S1 Kedokteran Universitas Airlangga',
      'Lisensi & Sertifikasi American Academy of Aesthetic Medicine',
    ],
    organisasi: 'IDI PERDAWERI',
    achievements: [
      'Advanced Master Course in Multimodality Approaches',
      'KOL Merz Aesthetics',
      'Ultherapy University',
      'Facial Aesthetics Master Class',
      'Aesthetic & Anti-Aging Medical World Congress',
      'Australasian Medical Aesthetics Congress',
      'Merz Injectables Train the Trainer',
      'Ultra V-Lift Advance Training',
    ],
  },
  {
    image: require('../assets/images/dokter/febriana.jpg'),
    name: 'dr. Febrina Primasanti, SpKK',
    position: 'Doctor/Dermatovenereologist',
    pendidikan: [
      'S1 Kedokteran Universitas Airlangga',
      'Profesi Spesialis Kulit & Kelamin Universitas Diponegoro',
    ],
    organisasi: 'PERDOSKI',
    achievements: ['Ultra V-Lift Advance Training'],
  },
]

const team = [
  {
    name: 'Kurnia Septiyanti',
    position: 'Suster',
  },
  {
    name: 'Retno Wijiyanti',
    position: 'Suster',
  },
  {
    name: 'Tri Wahyuni',
    position: 'Beautician',
  },
  {
    name: 'Iin Syafa’ah',
    position: 'Beautician',
  },
  {
    name: 'Siti Ruchayati',
    position: 'Beautician',
  },
]

export { dokter, team }
