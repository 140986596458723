import { useState, useRef } from 'react'
import { AiOutlineInstagram } from 'react-icons/ai'
import emailjs from '@emailjs/browser'

export default function Join(props) {
  const [isSending, setIsSending] = useState(false)
  const [status, setStatus] = useState('')
  const form = useRef()

  const sendMail = (e) => {
    e.preventDefault()
    setIsSending(true)

    emailjs
      .sendForm(
        'service_1b133qo',
        'template_pnqgejb',
        form.current,
        '2MTeMlFJCvHeT1Qo7'
      )
      .then(
        (result) => {
          setStatus(result.text)
        },
        (error) => {
          setStatus(error.text)
        }
      )
      .finally(() => {
        setTimeout(() => {
          setStatus('')
        }, 3000)
        setIsSending(false)
        form.current.reset()
      })
  }

  return (
    <section id="join">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="row images" style={{ backgroundColor: '#f5f5f5' }}>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-6">
                    <img
                      src={require('../assets/images/instagram/1.png')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src={require('../assets/images/instagram/2.jpg')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6">
                    <img
                      src={require('../assets/images/instagram/3.jpg')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  </div>
                  <div className="col-6">
                    <img
                      src={require('../assets/images/instagram/4.png')}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-3 mt-lg-0">
                <div className="big-image">
                  <img
                    src={require('../assets/images/instagram/5.jpg')}
                    style={{ width: '100%' }}
                    alt=""
                  />
                  <div className="instagram">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                      <AiOutlineInstagram size={50} />
                      <h5 className="mt-3">Get the look you want.</h5>
                      <a
                        href="https://www.instagram.com/lucentclinic.id"
                        className="btn btn-light"
                        style={{ fontSize: '13px', borderRadius: '0' }}
                      >
                        FOLLOW US ON INSTAGRAM
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-5 mt-lg-0 order-lg-first">
            <h2>Join the Lucent Community!</h2>
            <p>
              Jadilah yang pertama tau tentang treatment, produk, dan penawaran
              terbaru dari kami.
            </p>
            {status === 'OK' ? (
              <div
                className="alert alert-dark"
                style={{ borderRadius: '0' }}
                role="alert"
              >
                Selamat data diri kamu sudah kami terima, terima kasih sudah
                ingin bergabung dengan kami
              </div>
            ) : (
              status !== '' && (
                <div
                  className="alert alert-danger"
                  style={{ borderRadius: '0' }}
                  role="alert"
                >
                  Mohon maaf sedang ada kesalahan, coba beberapa saat lagi
                </div>
              )
            )}
            <form ref={form} onSubmit={sendMail}>
              <input
                type="text"
                placeholder="Nama Depan"
                name="nama"
                required
              />
              <input
                type="number"
                placeholder="No Telp"
                name="phone"
                required
              />
              <input type="email" placeholder="E-Mail" name="email" required />
              <button
                type="submit"
                className="btn btn-outline-dark mt-3 float-end px-4"
                style={{ borderRadius: '0' }}
                disabled={isSending ? true : false}
              >
                <div className="d-flex align-items-center">
                  {isSending && (
                    <div
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  )}
                  <span>Sign Up</span>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
