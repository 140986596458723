import { Link } from 'react-router-dom';

export default function ButtonAppointmentWa() {
    return (
        <div className="button-appointment-wa">
            <Link to="/appointment" className="appointment" >
                Appointment Online
            </Link>
            <a href="https://wa.me/628112778779" target={'_blank'} className="wa">
                <img src={require('../assets/images/icon/whatsapp.png')} />
            </a>
        </div>
    )
}