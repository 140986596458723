export default function Header(props) {
  return (
    <div
      id="header"
      style={{
        backgroundImage: `url(${require('../../assets/images/background/contact.jpg')})`,
      }}
    >
      <div className="container">
        <h1>Contact Us</h1>
        <div
          style={{
            backgroundColor: '#333',
            height: '2px',
            width: '100px',
            margin: '30px 0',
          }}
        ></div>
        <p>
          Ada pertanyaan, keluhan, ataupun rasa penasaran terhadap Lucent?
          Jangan ragu untuk menyampaikannya!
        </p>
      </div>
    </div>
  )
}
