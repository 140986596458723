import Scroll from 'react-scroll'
import { BsArrowDownCircle } from 'react-icons/bs'

export default function Hero(props) {
  const ScrollLink = Scroll.Link

  return (
    <div
      className="hero"
      style={{
        backgroundImage: `url(${require('../../assets/images/background/about.jpg')})`,
      }}
    >
      <h1 className="mb-4">Our Story</h1>
      <ScrollLink
        to="description"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <BsArrowDownCircle size={20} />
        <p className="mt-2">Scroll to Explore</p>
      </ScrollLink>
    </div>
  )
}
