import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'

export default function BtnSeeMore(props) {
  return (
    <Link to={props.to} className="btn-see-more">
      <div
        className={`d-flex align-items-center justify-content-${
          props.justify ?? 'start'
        }`}
      >
        {props.text}
        <BsArrowRight />
      </div>
    </Link>
  )
}
